.field-title {
    width: 100%;
    text-align: center;
}
  
.field-title h2 {
    color: white;
    font-size: 2.5rem;
}

.infoField {
    background-color: #00209F;
}

.other_fields_title {
    text-align: center;
    margin-top: 30px;
}
  
.other_fields_title h2 {
    color: #00209F;
    font-size: 2rem;
    font-weight: 900;
    display: inline-block;
    border-bottom: 2px solid #00209F;
    padding-bottom: 0.5rem;
}

.info-title{
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  color: white;
  font-size: 1.8rem;
  font-weight: 900;
  display: flex;
  border-bottom: 3px solid white;
  padding-bottom: 0.5rem;
}

section.infoField .info-contact .info-text {
  width: 100%;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  display: flex;
  align-items: flex-start;
}

section.infoField .info-contact .info-text li {
  text-decoration: none;
  list-style: none;
  width: 30px;
  height: 30px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  color: white;
}

section.infoField .info-contact .info-text p {
  margin: 0;
  padding: 0;
  color: white;
}

.map_container {
  text-align: center;
  margin-top: 30px;
}

@media (max-width: 767px) {
  .map-container iframe {
    width: 100%;
    height: 350px;
  }
}
  
.google_maps_button {
  margin-top: 20px;
  background-color: #00A4EF;
  color: white;
  font-size: 0.8rem;
  padding: 10px 20px;
  border-radius: 15px;
  text-decoration: none;
}
  
.google_maps_button:hover {
  background-color: #0082BF;
  color: white;
}


.gallery-title {
  color: #12AD2B;
  font-weight: bold;
  position: relative;
  display: inline-block;
}

.gallery-title::after {
  content: '';
  display: block;
  width: 100%;
  height: 1.8px;
  background-color: #12AD2B;
  margin: 0 auto;
  margin-top: 5px;
  border-radius: 2px;
}

.gallery-image {
  width: 170px;
  height: 170px !important;
  object-fit: cover;
  border-radius: 10px;
}