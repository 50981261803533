/*****************ESTILOS DE SWIPER******************************
/* Para asegurar que la imagen ocupe todo el espacio disponible */
.carousel-item-container {
  background-size: cover; /* Mantener la proporción de la imagen */
  background-position: center;
  background-repeat: no-repeat;
}

.loading-container {
  background: #d7d5d58c;
  background-size: cover;
  width: 100%;
  min-height: 830px ;
}
.archery-icon{
  width: 40px;
  height: 40px;
  opacity: 50%;
}
/* Cambia el color de las flechas a blanco */
.swiper-button-next,
.swiper-button-prev {
  color: #fff !important;
  opacity: 0.8;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  opacity: 1;
}
/*****************ESTILOS DE SWIPER*****************************/

.carousel-item-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Ajusta el nivel de oscuridad */
  z-index: 0; /* Coloca la capa oscura detrás del contenido */
}

.container, .cont-home {
  z-index: 1; /* Coloca el contenido encima de la capa oscura */
}

.cont-home {
  width: 75%;
  max-width: 1200px;
  margin-left: 50px;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-grow: 1;
}

.home-title h1 {
  color: #fff;
  font-weight: bolder;
  font-size: 3rem;
  text-align: left;
}

.home-description p {
  color: #fff;
  font-size: 2rem;
  font-weight: bolder;
  text-align: left;
}

.contentParrafoHome {
  width: 55%;
  margin-top: 0.6rem;
}

.contentParrafoHome p {
  color: #fff;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: left;
}

.scroll-down-Home {
  display: block;
  margin: 2rem auto;
  cursor: pointer;
  position: absolute !important;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  /* margin-left: -16px; */
  width: 35px !important;
  height: 35px !important;
  border: 2px solid #fff;
  background-size: 14px auto;
  border-radius: 50%;
  z-index: 2;
  animation: bounce 2s infinite 2s;
  transition: all 0.2s ease-in;
  transform: scale(1);
}

.scroll-down-arrow {
  position: relative;
  display: block;
  font-size: 1.5rem;
  top: 4px;
  left: 4px;
  color: #ffffff;
}

.buttons-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.buttons-container button {
  border: 2px solid #fff;
  border-radius: 25px;
  background: none;
  padding: 7px 25px 5px 25px;
  color: #fff;
  font-size: 1.3rem;
  transition: 0.3s ease all;
  cursor: pointer;
  width: 15rem;
}

.buttons-container button:hover {
  background: #ffffff85;
}

@media (max-width: 768px) {
  .contOurHome {
    /* padding: 3rem 1rem; */
    min-height: 1020px; /* Permite que la altura mínima se ajuste */
  }

  .cont-home {
    width: 80%;
  }

  .home-title h1 {
    font-size: 2.5rem;
  }

  .home-description p {
    font-size: 1.5rem;
  }

  .contentParrafoHome {
    width: 100%;
  }

  .buttons-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .buttons-container button {
    width: 100%;
  }

  .scroll-down-Home {
    bottom: 85px;
  }
}

@media (max-width: 560px) {
  .cont-home {
    width: 90%;
    margin-left: 10px;
  }
  
  /* .home-title h1 {
    font-size: 2rem;
  } */

  .scroll-down-Home {
    bottom: 40px;
  }
}

@media (max-width: 540px) {
  .scroll-down-Home {
    bottom: 50px;
  }
}

@media (max-width: 480px) {
  .contOurHome {
    min-height: 900px;
    /* padding: 2rem 1rem; */
  }

  .athletes-title h1 {
    font-size: 2rem;
  }

  .contentParrafoHome p {
    font-size: 1rem;
  }
}

@media (max-width: 400px) {
  .contOurHome {
    min-height: 990px;
    /* padding: 2rem 1rem; */
  }

  .scroll-down-Home {
    bottom: 4px;
  }
}

@media (max-width: 320px) {
  .contOurHome {
    min-height: 1185px;
    /* padding: 2rem 1rem; */
  }

  .scroll-down-Home {
    bottom: 33px;
  }
}
