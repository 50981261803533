.target-athlete-info {
  flex: 1, 2 280px;
  box-sizing: border-box;
  height: auto;
  width: 280px;
  background: #ffffff;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.25), 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
}

.target-athlete-info .image-container {
  position: relative;
  width: 100%;
  height: 280px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.target-athlete-info .image-container .athlete-card {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/imgAthletes/userGray.png);
  background-size: cover;
  object-fit: cover;
  border-radius: 10px;
}

.target-athlete-info .image-container .icon-association {
  position: absolute;
  width: 50px;
  bottom: 15px;
  right: 15px;
}

.target-athlete-info .information .icon {
  width: 20px;
  margin-left: 5px;
}

.target-athlete-info .information {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.target-athlete-info .information h6 {
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 5px;
}

.target-athlete-info .information button {
  border-radius: 25px;
  padding: 10px 0px;
  border: none;
  margin-top: 8px;
  width: 100%;
  text-align: center;
}

.target-athlete-info .information button.first {
  background: #FFD700;
}

.target-athlete-info .information button.second {
  background: #e5239d;
  color: #ffffff;
}

.target-athlete-info .information button.third {
  background: #00b5e6;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.information .third span {
  width: 100%;
}

.target-athlete-info .information .info-athlete {
  margin-top: 10px;
}

.target-athlete-info .information .info-athlete p {
  margin: 0;
  padding: 0;
}