.contRecords {
  background-image: url("../../assets/imgRecords/records-img.png");
  width: 100%;
  min-height: 830px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  background-size: cover;
  background-position: 40% 50%;
}

.container-records {
  margin-top: 40px;
  margin-bottom: 50px;
  width: 100%;
  overflow-x: auto;
}

.container-records .header-row {
  width: auto;
  min-width: 1100px;
  height: 40px;
  background: #00209f;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: 0 10px;
  font-family: paragraphText;
  font-size: 1rem;
}

.container-records .record-row {
  width: auto;
  min-width: 1100px;
  height: auto;
  min-height: 40px;
  border-radius: 3px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: paragraphText;
  font-size: .9rem;
  padding: 10px 10px;
  margin-top: 20px;
}

.category {
  width: 160px;
  min-width: 160px;
  max-width: 160px;
  text-align: center;
}

.athlete {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  text-align: center;
}

.state {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  text-align: center;
}

.competence {
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  text-align: center;
}

.punctuation {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  text-align: center;
}

.plus-x {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  text-align: center;
}

.x-section {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  text-align: center;
}

.record-row .category {
  border-radius: 25px;
  padding: 5px 0;
  color: #fff;
  cursor: pointer;
}