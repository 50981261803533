.contExecutiveBoard {
  background-image: url("../../assets/imgExecutiveBoard/WA-Image-2.png");
  width: 100%;
  min-height: 830px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem 6rem 1rem;
  background-size: cover;
  background-position: center;
}

.executive-title {
  width: 100%;
  text-align: center;
}

.executive-title h1 {
  font-size: 60px;
  font-weight: 600;
  color: #ffffff;
}

.contentParrafoExecutive {
  max-width: 650px;
  text-align: center;
  margin-top: 2rem;
}

.contentParrafoExecutive p {
  color: #fff;
  font-size: 1.25rem;
  font-weight: bold;
}

.council_Members_title {
  text-align: center;
  margin-top: 30px;
}

.council_Members_title h2 {
  color: #00209f;
  font-size: 2rem;
  font-weight: 900;
  display: inline-block;
  border-bottom: 2px solid #00209f;
  padding-bottom: 0.5rem;
}

.content_parrafo_councilMembers p {
  text-align: center;
  margin: 0 auto;
  max-width: 800px;
  font-size: 1rem;
}

.scroll-down {
  display: block;
  margin: 2rem auto;
  cursor: pointer;
}

.scroll-down-arrow {
  font-size: 2rem;
  color: #fff;
}

/* <--------------------> */
.cards-board {
  max-width: 163px; /* Ajusta este valor según tus necesidades */
  margin: auto;
  border: none !important;
  overflow: hidden;
}

.cards-board img {
  max-height: 162px;
  object-fit: cover;
  border-radius: 25px 25px 0px 0px !important;
}

.cards-body {
  border-radius: 0px 0px 25px 25px !important;
  padding: 20px !important;
  height: 210px !important;
  border: none !important;
}

.cards-body .card-title,
.cards-body .card-parrafo,
.cards-body .card-text {
  color: white;
  /* text-align: center; */
}

.cards-body .card-title {
  font-size: 18px;
  font-weight: 900;
}

.cards-body .card-parrafo {
  font-size: 14px;
  font-weight: 900;
}

.cards-body .card-text {
  font-size: 12px;
  font-weight: 500;
}

.scroll-down {
  position: relative !important;
  bottom: -230px;
  left: 52%;
  margin-left: -16px;
  display: block;
  width: 35px !important;
  height: 35px !important;
  border: 2px solid #fff;
  background-size: 14px auto;
  border-radius: 50%;
  z-index: 2;
  animation: bounce 2s infinite 2s;
  transition: all 0.2s ease-in;
  transform: scale(1);
}

.scroll-down-arrow {
  position: relative;
  display: block;
  font-size: 1.5rem;
  top: 4px;
  left: 4px;
  color: #ffffff;
}

.card-slider {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  overflow-x: hidden;
}

.card-slider .tagets-container {
  width: 100%;
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  padding: 10px;
  scroll-behavior: smooth;
  flex-wrap: nowrap;
}

@media screen and (min-width: 913px) and (max-width: 1280px) {
  .card-slider .tagets-container {
    gap: 1rem;
  }
}

/* ============================================================
  TABLETS
  ============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
  .cards-board {
    max-width: 210px; /* Ajusta este valor según tus necesidades */
    margin: 0px;
    border: none !important;
    overflow: hidden;
    margin-left: 14px;
  }

  .cards-board img {
    max-height: 200px;
    object-fit: cover;
    border-radius: 25px 25px 0px 0px !important;
  }

  .cards-body {
    border-radius: 0px 0px 25px 25px !important;
    padding: 20px !important;
    height: 210px !important;
    border: none !important;
  }

  .cards-body .card-title,
  .cards-body .card-parrafo,
  .cards-body .card-text {
    color: white;
    /* text-align: center; */
  }

  .cards-body .card-title {
    font-size: 30px;
    font-weight: 900;
  }

  .cards-body .card-parrafo {
    font-size: 23px;
    font-weight: 900;
  }

  .cards-body .card-text {
    font-size: 13px;
    font-weight: 500;
  }

  .card-slider .tagets-container {
    justify-content: flex-start;
    gap: 2rem;
  }
}

/* ============================================================
    PHONE
    ============================================================ */
@media screen and (max-width: 600px) {
  .cards-board {
    max-width: 236px; /* Ajusta este valor según tus necesidades */
    margin: 0px;
    border: none !important;
    overflow: hidden;
  }

  .cards-board img {
    max-height: 230px;
    object-fit: cover;
    border-radius: 25px 25px 0px 0px !important;
  }

  .cards-body {
    border-radius: 0px 0px 25px 25px !important;
    padding: 20px !important;
    height: 210px !important;
    border: none !important;
  }

  .cards-body .card-title,
  .cards-body .card-parrafo,
  .cards-body .card-text {
    color: white;
    /* text-align: center; */
  }

  .cards-body .card-title {
    font-size: 35px;
    font-weight: 900;
  }

  .cards-body .card-parrafo {
    font-size: 23px;
    font-weight: 900;
  }

  .cards-body .card-text {
    font-size: 13px;
    font-weight: 500;
  }

  .card-container {
    margin-left: 66px;
  }

  .card-slider .tagets-container {
    justify-content: flex-start;
    gap: 4rem;
  }
}
