.fields-hero {
  background-image: url("../../assets/imgFields/fields-img-2.png");
  width: 100%;
  height: 830px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  background-size: cover;
  background-position: 70% 50%;
}

.fields-title {
  width: 100%;
  text-align: center;
}

.fields-title h1 {
  font-size: 60px;
  font-weight: 600;
  color: #ffffff;
}

.contentParrafoFields {
  max-width: 700px;
  text-align: center;
  margin-top: 2rem;
}

.contentParrafoFields p {
  color: #fff;
  font-size: 1.25rem;
  font-weight: bold;
}

.our_fields_title {
  text-align: center;
  margin-top: 30px;
}

.our_fields_title h2 {
  color: #00209f;
  font-size: 2rem;
  font-weight: 900;
  display: inline-block;
  border-bottom: 2px solid #00209f;
  padding-bottom: 0.5rem;
}

.custom-container {
  max-width: 70% !important; /* Ajusta este valor según sea necesario */
}

@media (max-width: 767px) {
  .custom-container {
    max-width: 90% !important; /* Ajusta este valor según sea necesario */
  }
}

/*Estilos para input y selects*/
.search-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 56% !important;
}

.search-container .search-input {
  padding-left: 40px; /* Ajusta el padding para que el texto no se superponga al icono */
  width: 100%;
  border: #00209f solid 1px !important;
  border-radius: 18px !important;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.25),
    0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important;
}

.search-icon {
  position: absolute;
  left: 15px !important;
  color: #00209f;
  pointer-events: none;
}

.select-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 56% !important;
}

.select-filter {
  width: 100%;
  padding-right: 30px; /* Espacio para el icono */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: #00209f solid 1px !important;
  border-radius: 18px !important;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.25),
    0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important;
}

.select-icon {
  position: absolute;
  right: 10px;
  top: 13px; /* Ajusta este valor para centrar el icono verticalmente */
  pointer-events: none;
  color: #00209f;
}

@media (max-width: 767px) {
  .search-container,
  .select-container {
    width: 100% !important; /* Ocupa el 100% del ancho en pantallas pequeñas */
  }
}
/*Estilos para input y selects*/

.slick-slider {
  position: relative;
}

/* Flechas de slider */
.arrow {
  font-size: 20px;
  width: 25px; /* Ancho de la flecha */
  height: 25px; /* Alto de la flecha */
  color: white;
  background-color: #e5239d;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100; /* Asegura que las flechas estén sobre el contenido */
  cursor: pointer;
}

.arrow-left {
  left: -30px; /* Ajusta la distancia desde el borde izquierdo */
}

.arrow-right {
  right: -30px; /* Ajusta la distancia desde el borde derecho */
}

@media (max-width: 767px) {
  .arrow-left {
    left: -10px; /* Ajusta la distancia desde el borde izquierdo */
  }
  .arrow-right {
    right: -10px; /* Ajusta la distancia desde el borde derecho */
  }
}
/* Flechas de slider */
