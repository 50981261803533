/* General styles */
.input-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem; /* Add space between inputs */
}

.container-lista-atletas{
  --bs-gutter-x: 1.5rem;
--bs-gutter-y: 0;
width: 100%;
padding-right: calc(var(--bs-gutter-x) * .5);
padding-left: calc(var(--bs-gutter-x) * .5);
margin-right: auto;
margin-left: auto;
max-width: 1110px;
}

.contOurAthletes {
  background-image: url("../../assets/imgAthletes/athletes-2.png");
  width: 100%;
  min-height: 830px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  background-size: cover;
  background-position: center;
}

.athletes-title {
  width: 100%;
  text-align: center;
}

.athletes-title h1 {
  font-size: 60px;
  font-weight: 600;
  color: #ffffff;
}

.athletes-title h2 {
  color: #fff;
  font-size: 2.5rem;
}

.contentParrafoAthletes {
  max-width: 650px;
  text-align: center;
  margin-top: 2rem;
}

.contentParrafoAthletes p {
  color: #fff;
  font-size: 1.25rem;
  font-weight: bold;
}

.our_Athletes_title {
  text-align: center;
  margin-top: 30px;
}

.our_Athletes_title h2 {
  color: black;
  font-size: 2rem;
  font-weight: 900;
}

.content_parrafo_ourAthletes p {
  text-align: center;
  margin: 0 auto;
  max-width: 800px;
  font-size: 1rem;
}

.scroll-down {
  display: block;
  margin: 2rem auto;
  cursor: pointer;
  position: relative !important;
  bottom: -230px;
  left: 52%;
  margin-left: -16px;
  width: 35px !important;
  height: 35px !important;
  border: 2px solid #fff;
  background-size: 14px auto;
  border-radius: 50%;
  z-index: 2;
  animation: bounce 2s infinite 2s;
  transition: all 0.2s ease-in;
  transform: scale(1);
}

.scroll-down-arrow {
  position: relative;
  display: block;
  font-size: 1.5rem;
  top: 4px;
  left: 4px;
  color: #ffffff;
}

/* Estilos para la lista de elementos */
.athletes-list .list {
display: flex;
flex-direction: column;
gap: 15px; /* Espacio entre los elementos de la lista */
}

.athletes-list .list-item {
display: flex;
flex-direction: column;
align-items: center;
padding: 15px;
border: 1px solid #ddd;
border-radius: 8px;
background-color: #fff;
box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.25), 0px 2px 10px 0px rgba(0, 0, 0, 0.25); 
}

.item-content {
display: flex;
justify-content: space-between; /* Espacio entre la parte izquierda y derecha */
width: 100%; /* Ocupa todo el ancho del contenedor */
}

.left-content {
display: flex;
align-items: center;
gap: 15px; /* Espacio entre la imagen y los textos */
flex: 1; /* Ocupa todo el espacio disponible a la izquierda */
}

.right-content {
display: flex;
align-items: center;
gap: 10px; /* Espacio entre el círculo azul y el ícono de flecha */
}

.circle-container {
display: flex;
align-items: center;
}

.circle {
width: 55px;
height: 55px;
border-radius: 50%;
background-color: #00209F;
display: flex;
align-items: center;
justify-content: center;
color: #fff;
font-size: 10px;
text-align: center;
font-style: normal;
font-weight: 500;
}

.item-image {
width: 70px;
height: 70px;
object-fit: cover;
}

.text-container {
display: flex;
flex-direction: column;
gap: 5px;
}

.item-name,
.item-lastname {
font-size: 1rem;
}

.item-icon {
color: #00209F;
font-size: 1.5rem;
cursor: pointer;
transition: transform 0.3s ease;
}

.item-icon.rotated {
transform: rotate(180deg);
}

/*Estilos para paginación*/
.pagination {
display: flex;
justify-content: center;
align-items: center;
gap: 10px;
margin-top: 20px;
}

.pagination button {
border: 2px solid #DFE3E8;
border-radius: 4px;
background-color: transparent;
cursor: pointer;
padding: 5px 10px;
font-size: 15px;
font-weight: bold;
}

.pagination button.active {
border: 2px solid #00209F;
border-radius: 4px;
color: #00209F;
font-weight: bold;
}

.pagination button:disabled {
cursor: not-allowed;
opacity: 0.5;
}

.pagination .arrow-button {
background-color: #00209F;
color: #ffffff;
padding: 9px 9px;
border-radius: 4px;
display: flex;
align-items: center;
justify-content: center;
}

.pagination .arrow-icon {
color: #ffffff;
}

.pagination .dots {
padding: 5px 10px;
font-size: 16px;
color: black; /* Asegurarse de que el color sea visible */
}

/*nuevos estilos agregados*/

/* Añadido para el comportamiento de dropdown */

.athletes-list .list-item {
position: relative; /* Necesario para que el contenido adicional se posicione correctamente */
}

.athletes-list .additional-info {
display: none; /* Oculta el contenido adicional por defecto */
width: 100%; /* Asegura que el contenido adicional ocupe todo el ancho del list-item */
background-color: #f9f9f9; /* Color de fondo opcional */
padding: 10px;
border-top: 1px solid #ddd; /* Línea de separación opcional */
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); /* Sombra opcional */
position: absolute; /* Posiciona el contenido adicional sobre el list-item */
top: 100%; /* Coloca el contenido justo debajo del list-item */
left: 0;
z-index: 1;
transition: max-height 0.5s ease-out;
/* transition: max-height 0.3s ease, opacity 0.3s ease; Suaviza la transición */
max-height: 0; /* Inicialmente colapsado */
opacity: 0; /* Inicialmente invisible */
overflow: hidden; /* Oculta el contenido desbordante */
}

.athletes-list .additional-info.show {
display: block; /* Muestra el contenido adicional cuando tiene la clase 'show' */
max-height: 500px; /* Ajusta el valor según sea necesario */
opacity: 1;
margin-bottom: 20px; /* Hace visible el contenido adicional */
}

/* Estilos adicionales para asegurarse de que el contenido adicional empuje los demás elementos hacia abajo */
.athletes-list .list-item.expanded {
margin-bottom: 220px;
transition: margin-bottom 0.5s ease-out;
}

/*nuevos estilos agregados*/


.athletes-container-cards {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 35px;
}

.show-more {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.show-more button {
  padding: 10px 25px;
  border: none;
  background: #00209f;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

/* ===================================================== */

.search-inputs-container {
  width: 100%;
  min-width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
}

.search-inputs-container .input-container {
  flex: 1 1 200px;
  min-width: 150px;
  position: relative;
  display: flex;
  flex-direction: column;
}