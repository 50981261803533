@font-face {
  font-family: titleText;
  src: url(../src/fonts/Helvetica\ Neue\ 67\ Medium\ Condensed.otf);
}

@font-face {
  font-family: paragraphText;
  src: url(../src/fonts/HelveticaNeue-Condensed.otf);
}

h1, h2, h3, h4, h5, h6 {
  font-family: titleText;
}

p, button {
  font-family: paragraphText;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* INPUTS STYLES */
.search-inputs-container .input-container:last-child {
  max-width: 200px;
}

.input-container .input-icon {
  display: flex;
  position: absolute;
  width: 30px;
  height: 40px;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 5px;
  color: #00209F;
  font-size: 1.3rem;
}

.input-container .input-select-icon {
  display: flex;
  position: absolute;
  width: 30px;
  height: 40px;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 5px;
  color: #00209F;
  font-size: 1.3rem;
}

.input-container .input-text {
  width: 100%;
  height: 40px;
  border-radius: 50px;
  display: flex;
  border: 1px solid #00209f;
  padding-left: 35px;
  background: #fff;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.25), 0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important;
  transition: .2s ease all;
  overflow: hidden;
}

.input-container .input-select {
  width: 100%;
  height: 40px;
  border-radius: 50px;
  display: flex;
  border: 1px solid #00209f;
  padding-left: 10px;
  background: #fff;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.25), 0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important;
  transition: .2s ease all;
  overflow: hidden;
}

.input-container .input-text:focus-visible {
  border: 1px solid #00b5e6;
}

.reset-btn button {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #00209f;
  background: #00209F;
  color: #FFF;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.25), 0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important;
  transition: .3s ease all;
}

.reset-btn button:hover {
  background: #fff;
  color: #00209F;
}
