.notfound-hero {
    background-image: url("../../assets/imgTerms/WA-Image.png");
    background-position: center;
    width: 100%;
    height: 830px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.notfound-hero h1 {
    font-size: 60px;
    font-weight: 600;
    color: #ffffff;
}

.buttons-container a {
    border: 2px solid #fff;
    border-radius: 25px;
    background: none;
    padding: 7px 25px 5px 25px;
    color: #fff;
    font-size: 1.3rem;
    transition: .3s ease all;
    cursor: pointer;
    font-family: titleText;
    text-decoration: none;
}