.contOurEvents {
  background-image: url("../../assets/imgEvents/event-1.png");
  width: 100%;
  min-height: 830px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  background-size: cover;
  background-position: 30% 50%;
}

.events-title {
  width: 100%;
  text-align: center;
}

.events-title h1 {
  font-size: 60px;
  font-weight: 600;
  color: #ffffff;
}

.contentParrafoEvents {
  max-width: 650px;
  text-align: center;
  margin-top: 2rem;
}


.contentParrafoEvents p {
  color: #fff;
  font-size: 1.25rem;
  font-weight: bold;
}

.our_events_title {
  text-align: center;
  margin-top: 30px;
}


.our_events_title h2 {
  color: #00209F;
  font-size: 2rem;
  font-weight: 900;
  display: inline-block;
  border-bottom: 2px solid #00209F;
  /* padding-bottom: 0.5rem; */
}

/* Flechas */
.arrow-Event {
  font-size: 20px;
  width: 25px;  /* Ancho de la flecha */
  height: 25px; /* Alto de la flecha */
  color: white;
  background-color: #00b5e6;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000; /* Asegura que las flechas estén sobre el contenido */
  cursor: pointer;
}

.arrow-left-Event {
  left: -30px; /* Ajusta la distancia desde el borde izquierdo */
}

.arrow-right-Event {
  right: -30px; /* Ajusta la distancia desde el borde derecho */
}

.contenedor-inputs-events{
  --bs-gutter-x: 1.5rem;
--bs-gutter-y: 0;
width: 100%;
padding-right: calc(var(--bs-gutter-x) * .5);
padding-left: calc(var(--bs-gutter-x) * .5);
margin-right: auto;
margin-left: auto;
max-width: 1110px;
}

.input-row-events {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem; /* Add space between inputs */
}