.fields-target-info {
    width: 300px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.image-container {
    position: relative;
    width: 100%;
    height: 260px; /* Altura fija para las imágenes */
    overflow: hidden;
}

.target-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Asegura que la imagen cubra todo el contenedor, recortando si es necesario */
    /*display: block;*/
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    filter: brightness(0.5);
}

.title-overlay {
    position: absolute;
    bottom: 10px; /* Ajusta la posición del texto dentro de la imagen */
    left: 0;
    width: 100%;
    text-align: center;
    color: white;
    padding: 10px 0;
}

.target-title {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}

.info-container {
    background-color: #E5239D;
    padding: 15px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100px; /*Ajustar tamaño del container rosa*/
}

.target-address {
    margin: 10px 0 0;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.location-icon {
    margin-right: 8px;
    color: white;
    font-size: 28px;
}