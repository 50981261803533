.square-card-container {
  width: 300px;
  height: 300px;
  min-width: 300px;
  min-height: 300px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.square-card-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0.6) 100%);
  z-index: 1;
}

.square-card-container p {
  position: absolute;
  color: #fff;
  z-index: 3;
  font-size: 3rem;
  font-weight: bolder;
  font-family: titleText;
  margin: 0;
  padding: 0;
}