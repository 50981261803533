.contOurAboutUs {
  background-image: url("../../assets/imaAbout/WA-Image.png");
  width: 100%;
  min-height: 830px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  background-size: cover;
  background-position: center;
}


.aboutUs-title {
  width: 100%;
  text-align: center;
}

.aboutUs-title h1 {
  font-size: 60px;
  font-weight: 600;
  color: #ffffff;
}

.contentParrafoAboutUs {
  max-width: 650px;
  text-align: center;
  margin-top: 2rem;
}

.contentParrafoAboutUs p {
  color: #fff;
  font-size: 1.25rem;
  font-weight: bold;
}

/*seccion AboutUs Info*/
.aboutUs-info {
  padding: 60px;
}

.about-us-info-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* width: 90%; */
}

.left{
  flex: 1;
  padding: 10px;
}

.about-us-info-image {
  max-width: 100%;
  height: auto;
  width: 95%;
}

.row-title h1 {
  font-size: 2em;
}

.right{
  display: flex;
  flex-direction: column;
}

.row-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-top: 15px;
}

.row {
  flex-basis: 30%;
  min-width: 200px;
  margin: 10px 0;
}

.row-title h1 {
  font-size: 35px;
  color: #00209f;
  border-bottom: 2px solid #00209f;
  width: 13rem;
  padding-bottom: 0.5rem;
}

.row-content .row h3 {
  color: #171717;
  font-size: 25px;
  font-weight: bold;
}

.row-content .row li {
  color: #525252;
  font-size: 23px;
  font-weight: bold;
  list-style: none;
}
/*seccion AboutUs Info*/

/*section AboutUs Historia*/
.aboutUs-history {
  background-color: #00209f;
  width: 100%;
  padding: 20px;
}

.about-us-history-content {
  width: 100%;
  height: 100%;
  padding: 55px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 60px;
}

.left {
  flex: 1;
  padding: 10px;
}

.left h1 {
  color: #dfe3f3;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  border-bottom: 2px solid white;
  padding-bottom: 0.2rem;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 15px;
}

.left p {
  color: #dfe3f3;
  font-size: 19px;
  font-weight: bold;
  text-align: center;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.right {
  flex: 1;
  padding: 10px;
}

.right img {
  max-width: 100%;
  height: auto;
}
/*section AboutUs Historia*/

/*section AboutUs Mision*/
.about-us-mision {
  width: 100%;
  height: auto; /* Permite que la altura se ajuste automáticamente */
  padding: 20px 0; /* Añade padding en la parte superior e inferior */
}

.about-us-mision .about-us-mision-content {
  width: 95%;
  height: auto; /* Permite que la altura se ajuste automáticamente */
  margin-top: 45px;
  margin-left: auto;
  margin-right: auto; /* Centra el contenido horizontalmente */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Permite que las columnas se reorganicen en líneas nuevas */
}

.about-us-mision .about-us-mision-content .about-us-mision-row {
  width: 31.6%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box; /* Asegura que padding esté incluido en el width */
}

.about-us-mision .about-us-mision-content .about-us-mision-row h1 {
  margin-bottom: 20px;
  color: #00209f;
  font-size: 40px;
  font-weight: bold;
}

.about-us-mision .about-us-mision-content .about-us-mision-row p {
  font-size: 20px;
  font-weight: bold;
  color: #737373;
}
/*section AboutUs Mision*/

/* Ajustes para pantallas más pequeñas */
@media (max-width: 1024px) {
  .about-us-mision .about-us-mision-content .about-us-mision-row {
    width: 48%; /* Reduce el tamaño de las columnas para caber en dos líneas */
    margin-bottom: 20px; /* Añade espacio entre filas */
  }
}

@media (max-width: 768px) {
  .about-us-info-row {
    flex-direction: column;
  }

  .aboutUs-info {
    padding: 30px;
  }

  .left{
    flex: none;
    width: 100%;
  }

  .right {
    flex-direction: row; /* Cambia a disposición en fila en pantallas medianas */
    flex-wrap: wrap; /* Permite que las filas se envuelvan si es necesario */
  }

  .row {
    flex-basis: 45%;
    min-width: 150px;
  }

  .about-us-history-content {
    flex-direction: column;
    padding: 15px;
  }

  .left{
    flex: none;
    width: 100%;
    padding: 10px 0;
  }

  h1 {
    font-size: 1.8em;
  }

  p {
    font-size: 1em;
  }

  .about-us-mision .about-us-mision-content .about-us-mision-row {
    width: 100%; /* Las columnas ocupan todo el ancho en pantallas pequeñas */
    margin-bottom: 0;
    padding: 10px;
  }

  .about-us-mision .about-us-mision-content {
    /* margin-left: 0; Elimina el margen izquierdo en pantallas pequeñas */
    justify-content: center; /* Centra el contenido en pantallas pequeñas */
  }

  .about-us-mision .about-us-mision-content .about-us-mision-row h1 {
    font-size: 30px; /* Ajusta el tamaño de fuente en pantallas pequeñas */
  }

  .about-us-mision .about-us-mision-content .about-us-mision-row p {
    font-size: 18px; /* Ajusta el tamaño de fuente en pantallas pequeñas */
  }
}

@media (max-width: 480px) {
  .about-us-mision {
    padding: 0;
  }
  .aboutUs-history {
    padding: 5px;
  }
  .row {
    flex-basis: 48%;
    min-width: 100%; /* Las filas ocupan todo el ancho en pantallas muy pequeñas */
  }

  h1 {
    font-size: 1.5em;
  }

  p {
    font-size: 0.9em;
  }

  .about-us-mision .about-us-mision-content .about-us-mision-row h1 {
    font-size: 24px; /* Ajusta aún más el tamaño de fuente para pantallas muy pequeñas */
  }

  .about-us-mision .about-us-mision-content .about-us-mision-row p {
    font-size: 16px; /* Ajusta aún más el tamaño de fuente para pantallas muy pequeñas */
  }
}

