.scroll-down {
  opacity: 1;
  transition: all 0.5s ease-in 3s;
  position: absolute;
  bottom: 30px;
  left: 40%;
  transform: translateX(-50%);
  display: block;
  width: 32px;
  height: 32px;
  border: 2px solid #fff;
  border-radius: 50%;
  z-index: 2;
  animation: bounce 2s infinite 2s;
}

.scroll-down-arrow {
  display: block;
  font-size: 1.5rem;
  top: 3px;
  left: 2px;
  color: #ffffff;
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
