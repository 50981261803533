/* Estilos generales */
.title-with-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
}

.title-with-description h3 {
  font-weight: bolder;
  font-family: titleText;
  font-size: 2.7rem;
}

.title-with-description p {
  width: 80%;
  margin-top: 10px;
}

.slider-archery-home {
  margin-top: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  overflow-x: hidden;
}

.slider-archery-home i {
  font-size: 2rem;
  color: #b0b0b0;
  font-weight: lighter;
}

.slider-archery-home .tagets-container {
  display: flex;
  gap: 35px;
  overflow-x: auto;
  padding-bottom: 10px;
  scroll-behavior: smooth;
}

/* Donaciones y eventos */
.donations-and-events-container {
  background-color: #00b5e6;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 40px;
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
}

.donations-and-events-container::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 60%;
  background-color: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.donations-and-events-container .donations,
.donations-and-events-container .events {
  color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 10px;
}

.donations-and-events-container h4 {
  font-size: 3.5rem;
  width: 60%;
  font-weight: bold;
  padding-bottom: 10px;
}

.donations-and-events-container p {
  font-size: 21px;
  width: 60%;
  padding-top: 10px;
}

.donations-and-events-container .btn-donation {
  display: flex;
  gap: 10px;
  border: 2px solid #ffffff;
  padding: 7px 20px;
  background: #00209f;
  color: #fff;
  font-family: paragraphText;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
}

.donations-and-events-container form {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.donations-and-events-container form select {
  font-size: 16px;
  background-color: transparent;
  border: 2px solid #e5e5e5;
  border-radius: 10px;
  color: #e5e5e5;
  width: 414px;
  height: 50px;
  padding: 10px;
  padding-left: 40px;
  padding-right: 30px;
}

.donations-and-events-container form button {
  border: 2px solid #ffffff;
  padding: 7px 20px;
  background: #00209f;
  color: #fff;
  font-family: paragraphText;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
}

/* Mercancias */
.product-section {
  background-color: #00209f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
}

.product-section h3 {
  color: #ffffff;
  font-weight: bolder;
  font-family: titleText;
  font-size: 2.7rem;
  margin-top: 20px;
}

.product-section p {
  color: #ffffff;
  width: 50%;
  margin-top: 10px;
  font-size: 1rem;
}

.product-section-slider {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  overflow-x: hidden;
}

.product-section-slider i {
  font-size: 2rem;
  color: #b0b0b0;
  font-weight: lighter;
}

.product-section-slider .tagets-container {
  width: 100%;
  display: flex;
  gap: 7rem;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  padding: 10px;
  scroll-behavior: smooth;
  flex-wrap: nowrap;
}

/* Atletas */
.athletes-section-home {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
}

.athletes-section-home h3 {
  color: #212529;
  font-weight: bolder;
  font-family: titleText;
  font-size: 2.7rem;
}

.athletes-section-home p {
  color: #212529;
  width: 50%;
  margin-top: 10px;
  font-size: 1rem;
}

.athletes-section-home-slider {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  overflow-x: hidden;
}

.athletes-section-home-slider i {
  font-size: 2rem;
  color: #b0b0b0;
  font-weight: lighter;
}

.athletes-section-home-slider .tagets-container {
  width: 100%;
  display: flex;
  gap: 7rem;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  padding: 10px;
  scroll-behavior: smooth;
  flex-wrap: nowrap;
}

/* ============================================================
  TABLETS
  ============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {  
  .title-with-description h3 {
    font-size: 2rem;
  }

  .title-with-description p {
    width: 90%;
  }

  .slider-archery-home .tagets-container {
    gap: 15px;
  }

  /* Donacion y eventos */
  .donations-and-events-container {
    flex-direction: column;
  }

  .donations-and-events-container::before {
    content: "";
    position: absolute;
    width: 60%;
    height: 4px; /* Grosor de la línea */
    background-color: #ffffff; /* Color de la línea */
    top: auto;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 65px; /* Aumentar espacio entre la línea y el contenido */
  }

  .donations-and-events-container .donations,
  .donations-and-events-container .events {
    color: #ffffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin: 40px 10px;
  }

  .donations-and-events-container h4 {
    font-size: 3.5rem;
    width: 60%;
    font-weight: bold;
  }

  .donations-and-events-container p {
    font-size: 21px;
    width: 60%;
  }

  .donations-and-events-container .btn-donation {
    display: flex;
    gap: 10px;
    border: 2px solid #ffffff;
    padding: 7px 20px;
    background: #00209f;
    color: #fff;
    font-family: paragraphText;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;
  }

  .donations-and-events-container form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 10px;
  }

  .donations-and-events-container form select {
    font-size: 16px;
    font-weight: 600;
    background-color: transparent;
    border: 2px solid #e5e5e5;
    border-radius: 10px;
    color: #e5e5e5;
    width: 414px;
    height: 50px;
    padding: 10px;
    padding-left: 40px;
    padding-right: 30px;
  }

  .donations-and-events-container form button {
    border: 2px solid #ffffff;
    padding: 7px 20px;
    font-size: 16px;
    font-weight: 600;
    width: 414px;
    height: 50px;
    background: #00209f;
    color: #fff;
    font-family: paragraphText;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;
  }

  /* Mercancia */
  .product-section-slider .tagets-container {
    justify-content: flex-start;
    gap: 2rem;
  }

  .product-section p {
    width: 75%;
  }

  /* Atletas */
  .athletes-section-home-slider .tagets-container {
    justify-content: flex-start;
    gap: 2rem;
  }
}

/* ============================================================
    PHONE
    ============================================================ */
@media screen and (max-width: 600px) {
  .title-with-description h3 {
    font-size: 2rem;
  }

  .title-with-description p {
    width: 90%;
  }

  .slider-archery-home .tagets-container {
    gap: 15px;
  }

  /* Donaciones y eventos */
  .donations-and-events-container {
    flex-direction: column;
    padding-top: 20px;
  }

  .donations-and-events-container::before {
    content: "";
    position: absolute;
    width: 70%;
    height: 4px; /* Grosor de la línea */
    background-color: #ffffff; /* Color de la línea */
    top: auto;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 140px; /* Aumentar espacio entre la línea y el contenido */
  }

  .donations-and-events-container .donations,
  .donations-and-events-container .events {
    color: #ffffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin: 40px 10px;
  }

  .donations-and-events-container .donations h4 {
    font-size: 3.5rem;
    width: 60%;
    font-weight: bold;
    margin-left: -31px;
  }

  .donations-and-events-container .donations p {
    font-size: 21px;
    width: 60%;
    margin-left: -3px;
  }

  .donations-and-events-container .btn-donation {
    display: flex;
    gap: 10px;
    border: 2px solid #ffffff;
    padding: 7px 20px;
    background: #00209f;
    color: #fff;
    font-family: paragraphText;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;
  }

  .donations-and-events-container .events h4 {
    font-size: 3.5rem;
    width: 60%;
    font-weight: bold;
    margin-left: -2px;
  }

  .donations-and-events-container .events p {
    font-size: 21px;
    width: 60%;
    margin-left: -3px;
  }

  .donations-and-events-container form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 10px;
  }

  .donations-and-events-container form select {
    font-size: 16px;
    font-weight: 600;
    background-color: transparent;
    border: 2px solid #e5e5e5;
    border-radius: 10px;
    color: #e5e5e5;
    width: 280px;
    height: 50px;
    padding: 10px;
    padding-left: 40px;
    padding-right: 30px;
  }

  .donations-and-events-container form button {
    border: 2px solid #ffffff;
    padding: 7px 20px;
    font-size: 16px;
    font-weight: 600;
    width: 280px;
    height: 50px;
    background: #00209f;
    color: #fff;
    font-family: paragraphText;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;
  }

  /* Mercancias */
  .product-section-slider .tagets-container {
    justify-content: flex-start;
    gap: 2rem;
  }

  .product-section p {
    width: 90%;
  }

  /* Atletas */
  .athletes-section-home-slider .tagets-container {
    justify-content: flex-start;
    gap: 2rem;
  }
}
