/* Estilos base */
footer {
  width: 100%;
  background-color: #00209f;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 10px;
}

.footer-nav {
  width: auto;
  list-style: none;
  display: flex;
  gap: 10px;
  margin: 0;
  padding: 0;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
}

.footer-nav ul li {
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  cursor: pointer;
}

.footer-nav li.active {
  background-color: #fff !important;
  border-radius: 50px !important;
  /* padding: 10px 20px; */
}

.footer-nav li.active a {
  color: #00209f !important;
}

.footer-nav li:hover {
  text-decoration: none;
  transition: background-color 0.3s ease;
  background-color: #fff;
  border-radius: 50px;
  /* padding: 10px 20px; */
}

.footer-nav li:hover a {
  color: #00209f !important;
}

.footer-nav li:last-child {
  border-right: none;
}

.footer-nav li a {
  text-decoration: none;
  color: white;
  margin: 0;
  padding: 10px 20px;
  display: block;
}

.social-icons {
  margin-top: 20px;
  display: flex;
  gap: 40px;
}

.social-icons a {
  color: white;
  font-size: 30px;
}

.footer-text {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 5px;
}

.footer-text p {
  color: white;
  margin: 0;
  padding: 0;
  text-align: center;
}

.container-privacity {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh; /* O ajusta a lo que necesites */
}

.links-container-privacity {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px; /* Ajusta el ancho máximo según necesites */
  padding: 0 20px;
  gap: 50px;
}

.link {
  text-decoration: none;
  color: #fff; /* O el color que prefieras */
  font-size: 1.2rem; /* Ajusta el tamaño según necesites */
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 50px;
  text-align: center;
  padding: 5px 15px;
}

.link:hover {
  background-color: #fff;
  color: #00209f; /* Ajusta el color del texto cuando se aplica hover */
}

/* Estilos responsivos */
@media screen and (max-width: 768px) {
  .footer-nav {
    flex-direction: column;
    align-items: center;
  }

  .footer-nav li {
    border-right: none;
    width: 100%;
    text-align: center;
  }

  .footer-nav li:last-child {
    border-bottom: none;
  }

  .social-icons {
    gap: 20px;
  }

  .container-privacity {
    height: 10vh;
  }

  .links-container-privacity {
    flex-direction: column;
    align-items: center;
    gap: 25px; /* Espacio entre los enlaces en pantallas pequeñas */
  }

  .link {
    font-size: 1rem;
  }
}
