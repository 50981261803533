.post-header-container {
  height: 180px;
  background-color: #00209f;
}
.post-news{
  font-size: 1.5rem;
  font-weight: 800;
  color: #00209f;
}
.post-description{
  font-size: 1.5rem;
  color: grey;
}
.post-body{
  font-size: 1.5rem;
  color: grey;
}
.post-img{
  width: 30%;
}

@media (max-width: 999px)  {  
  .post-header-container {
    height: 80px;
  }
}