.coaches-hero {
  background-image: url("../../assets/imgCoaches/coaches-image.png");
  width: 100%;
  height: 830px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  background-size: cover;
  background-position: center;
}

.coaches-title {
  width: 100%;
  text-align: center;
}

.coaches-title h1 {
  font-size: 60px;
  font-weight: 600;
  color: #ffffff;
}

.contentParrafoCoaches {
  max-width: 700px;
  text-align: center;
  margin-top: 2rem;
}

.contentParrafoCoaches p {
  color: #fff;
  font-size: 1.25rem;
  font-weight: bold;
}

.our_coaches_title {
  text-align: center;
  margin-top: 30px;
}

.our_coaches_title h2 {
  color: #00209F;
  font-size: 2rem;
  font-weight: 900;
  display: inline-block;
  border-bottom: 2px solid #00209F;
  padding-bottom: 0.5rem;
}

.content_parrafo_councilMembers p {
  text-align: center;
  margin: 0 auto;
  max-width: 800px;
  font-size: 1rem;
}

/*Estilos para las coach cards*/
.coach-card {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.25), 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
}

.coach-details {
  display: flex;
  flex-direction: column;
}

.coach-details p {
  margin: 3px 0;
  font-size: 18px;
  font-weight: bold
}

.coach-card-photo {
  width: 100px;
  height: 100px;
  padding: 6px 9px 6px 9px;
  margin-right: 20px;
}
/*Estilos para las coach cards*/

/*Estilos para paginación*/
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.pagination button {
  border: 2px solid #DFE3E8;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 15px;
  font-weight: bold;
}

.pagination button.active {
  border: 2px solid #00209F;
  border-radius: 4px;
  color: #00209F;
  font-weight: bold;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination .arrow-button {
  background-color: #00209F;
  color: #ffffff;
  padding: 9px 9px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .arrow-icon {
  color: #ffffff;
}

.pagination .dots {
  padding: 5px 10px;
  font-size: 16px;
  color: black;
}
/*Estilos para paginación*/

/*Estilos para input y selects*/
.search-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 56% !important;
}

.search-container .search-input {
  padding-left: 40px;
  width: 100%;
  border: 1px solid #00209f;
  border-radius: 18px !important;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.25), 0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important;
}

.search-icon {
  position: absolute;
  left: 15px !important;
  color: #00209F;
  pointer-events: none;
}

.select-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 56% !important;
}

.select-filter {
  width: 100%;
  padding-right: 30px; /* Espacio para el icono */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #00209f;
  border-radius: 18px;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.25), 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
}

.select-icon {
  position: absolute;
  right: 10px;
  top: 13px; /* Ajusta este valor para centrar el icono verticalmente */
  pointer-events: none;
  color: #00209F;
}

@media (max-width: 767px) {
  .search-container,
  .select-container {
    width: 100% !important; /* Ocupa el 100% del ancho en pantallas pequeñas */
  }
}
/*Estilos para input y selects*/