.contact-us-header {
  background-image: url("../../../assets/imgContact/Hero.jpg");
  width: 100%;
  height: 830px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  background-size: cover;
  background-position: center;
}

.title-contact {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.title-contact h4 {
  width: 50%;
  color: #00209f;
  text-align: center;
  font-size: 2.3rem;
  border-bottom: 3px solid #00209f;
  font-weight: bold;
  padding-bottom: 8px;
}

.contact-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  gap: 10px;
  margin-bottom: 50px;
}

.contact-container .info-contact {
  width: 50%;
  height: auto;
  margin: 0;
  padding: 0;
}

.contact-container .form-container {
  width: 50%;
  height: auto;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-contact .info-text {
  width: 100%;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  display: flex;
  align-items: flex-start;
}

.info-contact .info-text li {
  text-decoration: none;
  list-style: none;
  width: 30px;
  height: 30px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  color: #00209f;
}

.info-contact .info-text p {
  margin: 0;
  padding: 0;
}

.maps-container {
  width: 100%;
  height: 300px;
  overflow: hidden;
  margin-top: 20px;
}

.btn-tel-mb {
  display: none;
}

.btn-tel-dk {
  display: flex;
}

@media screen and (max-width: 600px) {
  .contact-container {
    display: flex;
    flex-direction: column;
  }
  .title-contact h4 {
    width: 100%;
  }
  .contact-container .info-contact {
    width: 100%;
  }
  .contact-container .form-container {
    margin-top: 20px;
    width: 100%;
  }
  .btn-tel-mb {
    display: flex;
    text-decoration: none;
    color: black;
    font-family: paragraphText;
    font-size: .9rem;
  }
  .btn-tel-dk {
    display: none;
  }
}