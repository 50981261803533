.product-circle {
  width: 300px;
  height: 300px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  position: relative;
  overflow: hidden; /* Importante para que el contenido no se salga del círculo */
}

.product-circle-image {
  width: 80%;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.product-circle-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #000000;
  opacity: 0;
  transition: opacity 9.3s;
  text-align: center;
  padding: 10px; /* Para que el texto no toque los bordes del círculo */
}

.product-circle-text span {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}

.product-circle-text p {
  font-size: 12px;
  margin: 0;
  width: 100%;
}

.product-circle:hover .product-circle-text {
  opacity: 1;
}

.product-circle:hover .product-circle-image {
  opacity: 0;
}

.response-content {
  display: none;
}

/* ============================================================
  TABLETS
  ============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
}

/* ============================================================
    PHONE
    ============================================================ */
@media screen and (max-width: 600px) {
  .product-circle {
    display: none;
  }

  .response-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .product-circle-responsive {
    width: 200px;
    height: 200px;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .product-circle-responsive .product-circle-image {
    width: 80%;
  }

  .responsive-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: #000000;
    text-align: center;
    padding: 10px;
    width: 294px;
  }

  .responsive-text span {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #ffffff;
  }

  .responsive-text p {
    font-size: 12px;
    margin: 0;
    width: 100%;
    color: #ffffff;
  }
}
