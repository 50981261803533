nav {
  width: 100%;
  height: 80px;
  background-color: #00209F;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50px;
  z-index: 10;
}

.contNav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  align-items: center;
}

.contImgNav {
  width: 150px;
  position: relative;
}

.contImgNav img {
  width: 100px;
  height: auto;
}

.textContainerNav {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.textContainerNav ul {
  position: relative;
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

#marker {
  position: absolute;
  left: 0;
  height: 4px;
  width: 0;
  background: rgb(251, 255, 251);
  bottom: -3px;
  transition: all .3s ease;
  border-radius: 4px;
}

.textContainerNav ul li {
  position: relative;
  margin: 0;
  padding: 0;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  cursor: pointer;
}

.textContainerNav ul li p {
  text-decoration: none;
  color: #fff;
}

/* .textContainerNav ul li.active{
  border-bottom: 2px solid #fff;
}

.textContainerNav ul li.active p{
  color: #fff;
} */

.textos{
  color: #fff;
}

.menuIcon {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  height: 30px;
  cursor: pointer;
}

.menuIcon .icon {
  width: 25px;
  height: 25px;
  color: #fff;
}

.nav-container {
  display: none;
}

/* Responsive styles */
/* @media (max-width: 768px) {
  .textContainerNav {
    position: absolute;
    top: 80px; 
    right: 0;
    width: 100%;
    background-color: #00209F;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    display: none; 
    transition: transform 0.3s ease-in-out;
    z-index: 9;
  }

  .textContainerNav.open {
    display: flex;
  }

  .textContainerNav ul {
    flex-direction: column;
    gap: 0;
  }

  .textContainerNav ul li {
    width: 100%;
    text-align: center;
    border-right: none;
    border-bottom: 1px solid #fff;
  }

  .textContainerNav ul li:last-child {
    border-bottom: none;
  }

  .menuIcon {
    display: flex;
  }
} */

/* ============================================================
TABLETS
============================================================ */
@media screen and (max-width: 1000px) {
  nav {
    width: 100%;
    height: 80px;
    background-color: #00209F;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    z-index: 100;
  }
  .contImgNav {
    width: auto;
    position: relative;
  }
  .contImgNav img {
    width: auto;
    height: 50px;
  }
  .menuIcon {
    display: flex;
  }
  .nav-container {
    width: 0px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: fixed;
    background: #00209F;
    z-index: 101;
    padding: 0px;
    gap: 10px;
    overflow: hidden;
    transition: .6s ease-in-out;
    left: -400px;
  }
  .nav-container.active {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: fixed;
    background: #00209F;
    z-index: 1000;
    padding: 20px;
    gap: 10px;
    overflow: hidden;
    left: 0;
  }
  .close-menu-icon {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
    transition: .3s ease all;
  }
  .close-menu-icon .icon:hover {
    color: #c42e2e;
  }
  .nav-container .logo-mb {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .nav-container .logo-mb img {
    width: 80px;
  }
  
  .nav-container .btns-container-nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }

  .nav-container ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
    gap: 20px;
    width: 100%;
  }

  .nav-container ul li {
    text-align: center;
    font-size: 1.5rem;
    border-radius: 25px;
    width: 65%;
    transition: .3s ease all;
  }
  
  .nav-container ul li:hover {
    background: #fff;
    color: #00209F;
  }

  .nav-container ul li p {
    margin: 0;
    padding: 10px;
  }

  .nav-container ul li.active {
    background: #fff;
    color: #00209F;
  }

  .textContainerNav {
    display: none;
  }
}