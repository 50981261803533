.privacy-hero {
  background-image: url("../../assets/imgPrivacy/WA-Image.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 830px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.privacy-hero h1 {
  font-size: 60px;
  font-weight: 600;
  color: #ffffff;
}

.contentParrafoPrivacy {
  max-width: 700px;
  text-align: center;
  margin-top: 2rem;
}

.contentParrafoPrivacy p {
  color: #fff;
  font-size: 1.25rem;
  font-weight: bold;
}

.privacy-content {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 30px;
}

.privacy-content-description {
  width: 90%;
  margin: 0;
  height: auto;
  margin-bottom: 30px;
}

.privacy-content-description p {
  color: #525252;
  font-size: 24px;
  font-weight: 600;
}

.privacy-content-collapse {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 85%;
  height: auto;
  margin-bottom: 20px;
}

.privacy-content-collapse .left-column {
  width: 559px;
  height: auto;
  padding: 25px;
  margin-left: 60px;
  margin-bottom: 13px;
}

.privacy-content-collapse .left-column p {
  text-align: justify;
  color: #303a42;
  font-size: 15px;
  font-weight: 600;
  padding-top: 13px;
}

.privacy-content-collapse .right-colum {
  width: 559px;
  height: auto;
  padding: 25px;
  margin-right: 60px;
  margin-bottom: 13px;
}

.privacy-content-collapse .right-colum p {
  text-align: justify;
  color: #303a42;
  font-size: 15px;
  font-weight: 600;
  padding-top: 13px;
}
/* ============================================================
TABLETS
============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
  .privacy-hero {
    background-size: cover;
    background-position: center;
  }

  .privacy-content-description {
    margin: 0;
    height: auto;
    margin-bottom: 0;
  }

  .privacy-content-collapse .left-column {
    margin-left: 10px;
  }

  .privacy-content-collapse .right-colum {
    margin-right: 10px;
  }
}

/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 600px) {
  .privacy-hero {
    background-size: cover;
    background-position: center;
  }

  .privacy-content-description {
    margin: 0;
    height: auto;
    margin-bottom: 0;
  }

  .privacy-content-description p {
    text-align: justify;
  }

  .privacy-content-collapse {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 85%;
    height: auto;
    margin-bottom: 20px;
  }

  .privacy-content-collapse .left-column {
    width: 100%;
    padding: 0;
    margin-left: 0px;
    margin-bottom: 0;
  }

  .privacy-content-collapse .right-colum {
    width: 100%;
    padding: 0;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 13px;
  }
}
