.accordion-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  background-color: #ffffff;
}

.accordion-header img {
  width: 130px;
  height: auto;
}

.accordion-header .no-logo img {
  opacity: 0;
  width: 130px; /* Mantiene el espacio reservado para el logo */
}

.accordion-header h3{
  font-size: 14px;
  color: #737373;
  padding: 5px;
  font-weight: 500;
  font-style: normal;
}

.accordion-header p{
  font-size: 12px;
  color: #737373;
  padding: 3px;
}

.accordion-header .title, 
.accordion-header .flag, 
.accordion-header .text, 
.accordion-header .arrowEvent {
  flex: 1; /* Distribuye los elementos de manera equitativa */
  text-align: center;
}
.arrow-position{
  position: absolute;
  bottom: 45%;
  right: 2%;
}

.title {
  font-size: 30px;
  font-weight: 900;
  font-family: titleText;
  margin-left: 10px;
}

.text {
  color: #737373;
  font-size: 25px;
  font-weight: 500;
  font-family: paragraphText;
  margin-left: 10px;
}

.flag {
  margin-top: 20px;
}

.flag img {
  width: 20px;
  height: 15px;
  margin-bottom: 5px;
}

.state-icon {
  width: 50px !important;
  height: auto !important;
}

.arrowEvent {
  color: #00209F;
  font-size: 25px;
  transition: transform 0.3s;
  text-align: right;
}

.arrowEvent.open {
  transform: rotate(180deg);
}

.accordion-content {
  padding: 20px;
  background-color: #f5f5f5;
}

.data-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.tags {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  gap: 10px;
  width: 100%;
  padding: 10px;
}

.tag-item1 {
  border: 1px solid #ffc726;
  border-radius: 70px;
  background-color: #ffc726;
  color: #171717;
  font-size: 20px;
  font-weight: 500;
  font-family: paragraphText;
  text-align: center;
  width: 250px;
}

.tag-item2 {
  border: 1px solid #e5239d;
  border-radius: 70px;
  background-color: #e5239d;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  font-family: paragraphText;
  text-align: center;
  width: 250px;
}

.links {
  height: 100px;
  width: 40%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.link-item-a {
  border: 1px solid #00b5e6;
  border-radius: 70px;
  background-color: #00b5e6;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  font-family: paragraphText;
  text-align: center;
  width: 230px;
}

.link-item-b {
  border: 1px solid #171717;
  border-radius: 70px;
  background-color: #171717;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  font-family: paragraphText;
  text-align: center;
  width: 230px;
}

.link-item-a a {
  color: #ffffff;
}
.link-item-b a {
  color: #ffffff;
}

.links-text {
  color: #262626;
}

.info {
  display: flex;
  height: 150px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.item {
  width: 250px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
}

.item h1 {
  color: #000000;
  font-size: 30px;
  font-weight: 600;
  font-family: titleText;
}

.item span {
  color: #262626;
  font-size: 20px;
  font-weight: 400;
  font-family: paragraphText;
}

/*Estilos para paginación*/
.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px !important;
  margin-top: 20px;
}

.pagination button {
  border: 2px solid #dfe3e8;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: bold;
}

.pagination button.active {
  border: 2px solid #00209f;
  border-radius: 4px;
  color: #00209f;
  font-weight: bold;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination .arrow-button {
  background-color: #00209f;
  color: #ffffff;
  padding: 7px 7px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .arrow-icon {
  color: #ffffff;
}

.pagination .dots {
  padding: 5px 10px;
  font-size: 16px;
  color: black;
}
/*Estilos para paginación*/

/* Estilos responsivos */
@media (max-width: 1400px) {
  .tag-item1{
    width: 250px;
    margin-left: 0px;
  }
  .tag-item2{
    width: 250px;
  }

  .accordion-header {
    flex-direction: row;
    justify-content: space-between;
  }
  .arrow-position{
    right: 1%;
  }
  .flag {
    margin-top: 15px;
  }
}

@media (max-width: 1200px) {
  .accordion-header {
    flex-direction: row;
    justify-content: space-between;
  }

  .title,
  .text {
    font-size: 24px; /* Ajusta el tamaño del texto */
  }

  .flag img {
    width: 18px;
    height: 14px;
  }

  .tag-item1{
    width: 230px;
    margin-left: 0px;
  }
  .tag-item2{
    width: 230px;
  }
}

@media (max-width: 990px) {
  .data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .tags {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
  }
  
  .tag-item1, .tag-item2 {
    width: 100%;
    /* max-width: 250px; */
    margin: 0 auto; /* Centra los elementos */
    text-align: center;
  }

  .link-item {
    width: 100%;
    height: 36px;
    margin-right: 0 !important;
  }
  
  .links {
    height: 100px;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .info {
    height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px; /* Asegura que el contenido no toque los bordes */
    text-align: center;
  }
  
  .accordion-header {
    /* flex-direction: column; */
    align-items: center;
    padding: 8px 10px;
  }
  
  .title {
    font-size: 22px;
    margin-bottom: 10px;
  }
  
  .text {
    font-size: 18px;
  }
  
  .flag, .arrowEvent {
    margin-top: 10px;
  }
  
}

@media (max-width: 768px) {
  .accordion-header {
    align-items: center;
    padding: 8px 10px;
  }
  .arrow-position{
    top: 14%;
    right: 5%;
  }

  .title {
    font-size: 22px;
  }

  .text {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .pagination {
    gap: 4px;
  }

  .pagination button {
    font-size: 12px;
    padding: 3px 6px;
  }

  .flag {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }

  .flag img {
    width: 20px;
    height: 15px;
  }

  .data-container {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .tags {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .tag-item1, .tag-item2 {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  }

  .links {
    flex-direction: column;
    width: 100%;
  }

  .link-item {
    border: 1px solid #171717;
    border-radius: 70px;
    background-color: #171717;
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    font-family: paragraphText;
    text-align: center;
    width: 100%;
    height: 36px;
    margin-right: 0px;
    cursor: pointer;
  }

  .arrowEvent {
    margin-top: 10px;
  }

  .info {
    height: 310px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 10px;
    text-align: center;
  }

  /* .arrowEvent{
    right: 0rem;
    top: 9% !important;
  } */
}

@media (max-width: 576px) {
  .arrow-left-Event {
    left: 4px !important;
  }
  .arrow-right-Event {
    right: 4px !important;
  }
  .info {
    height: 310px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 10px;
    text-align: center;
  }

  .accordion-header {
    padding: 5px 8px;
  }

  .title {
    font-size: 18px;
  }

  .text {
    font-size: 14px;
  }

  .flag {
    margin-top: 5px;
  }

  .arrowEvent {
    font-size: 20px;
    margin-top: 5px;
  }
}

@media (max-width: 320px) {
  .arrow-left-Event {
    left: -10px !important;
  }
  .arrow-right-Event {
    right: -10px !important;
  }

  .info {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 10px;
    text-align: center;
  }

  .item {
    width: 100%;
    max-width: 300px;
    margin-bottom: 15px;
  }
  .accordion-header {
    padding: 5px 5px;
  }

  .title {
    font-size: 16px;
  }

  .text {
    font-size: 12px;
  }

  .flag {
    margin-top: 5px;
  }

  .arrowEvent {
    font-size: 18px;
  }
}
