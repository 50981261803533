

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.home-container h1 {
  margin-bottom: 0;
}

.img-home-container {
  width: 100%;
  height: 100vh;
  padding: 2rem;
  background-image: url('../assets/imgHome/WC5MEX23_ALEjpg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.copyright {
  font-size: .8rem;
  text-align: center;
  color: gray;
}
