 
.swiper {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 100px;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    border-radius: 10px;
  }
  
  .mySwiper .swiper-slide {
    opacity: 0.9;
    transition: opacity 0.5s ease;
  }
  
  .mySwiper .swiper-slide-active {
    opacity: 1; 
  }