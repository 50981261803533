.form-contact {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-contact .section-row {
  width: 100%;
  display: flex;
  gap: 20px;
}

.form-contact .group-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.form-contact .group-input label {
  color: #00209f;
  font-family: paragraphText;
}

.form-contact .group-input input, .form-contact .group-input select {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #00209f;
  font-size: .9rem;
  padding: 8px 15px;
  font-family: paragraphText;
  box-shadow: 0px 3px 8px -3px rgba(0,0,0,0.69);
  background: #fff;
}

.form-contact .group-input input:focus {
  border: 1px solid #00209f;
  border-color: #00209f;
  box-shadow: 0px 0px 0px rgba(0,0,0,0);
}

.form-contact .group-input textarea {
  width: 100%;
  height: 150px;
  max-height: 150px;
  min-height: 150px;
  border-radius: 5px;
  border: 1px solid #00209f;
  box-shadow: 0px 3px 8px -3px rgba(0,0,0,0.69);
  padding: 8px 15px;
  font-family: paragraphText;
  font-size: .9rem;
}

.form-contact button {
  width: auto;
  background: #00209f;
  color: #fff;
  width: 150px;
  height: 40px;
  border: none;
  border-radius: 5px;
  font-size: .9rem;
}

.alert-input-text {
  color: red;
  font-size: .8rem;
  font-family: paragraphText;
  margin: 0;
  padding: 0;
  margin-bottom: -5px;
}

/* Thank you page */

.thankyou-hero{
  background-image: url("../../../assets/imgThankYou//indoor-banner-overlay.png");
  background-size: cover;
  background-position: right;
  width: 100%;
  height: 830px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* .thankyou-hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.thankyou-hero > * {
  position: relative;
  z-index: 2;
} */

.thankyou-hero h1{
  color: white;
}

.thankyou-hero h5{
  color: white;
}