.custom-collapse-container {
  width: 85%;
  margin-left: 35px;
}

.custom-collapse-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #7e7e7e;
  padding: 10px 0;
  height: 55px;
}

.custom-collapse-header.open {
  border-bottom: none;
}

.custom-collapse-title {
  color: #00209f;
  font-size: 20px;
  font-weight: 600;
}

.custom-collapse-icon {
  margin-left: auto;
  color: #00209f;
}

.custom-collapse-body {
  border-bottom: 1px solid #7e7e7e;
  padding-bottom: 10px;
}

/* ============================================================
TABLETS
============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
  .custom-collapse-container {
    width: 95%;
    margin-left: 12px;
  }
}

/* ============================================================
  PHONE
  ============================================================ */
@media screen and (max-width: 600px) {
  .custom-collapse-container {
    width: 90%;
    margin-left: 12px;
  }
}
